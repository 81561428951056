/* 共通 */
body {
  background: url("../images/bg.png") ;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

.textWrap {
  word-break: break-all;
}

.top_left_style {
  display: grid;
  place-items: center;
}

.number div > input {
  text-align: right; /* Fieldsの数値を右寄せにする */
}

.btn_gray, .btn_navy, .btn_red {
  display: inline-block;
}

.btn_navy {
  background: #2464a1;
  background: -webkit-gradient(linear, left top, left bottom, from(#588DBF),
    to(#2464a1)) !important;
  background: -webkit-linear-gradient(top, #588DBF 0%, #2464a1 100%)
    !important;
  background: linear-gradient(to bottom, #588DBF 0%, #2464a1 100%)
    !important;
  width: 180px;
  box-sizing: border-box;
  color: #fff !important;
}
.btn_gray {
  background: -webkit-gradient(linear, top, bottom, from(#898989),
    to(#646464)) !important;
  background: -webkit-linear-gradient(top, #898989 0%, #646464 100%)
    !important;
  background: linear-gradient(to bottom, #898989 0%, #646464 100%) !important;
  width: 180px;
  color: #fff;
}

.btn_red {
  background: linear-gradient(to bottom, #ec7a6c 0%, #dd4b39 100%)
    !important;
  background: -webkit-linear-gradient(top, #ec7a6c 0%, #dd4b39 100%)
    !important;
  width: 160px;
}

.help_image_alt {
  display:block;
  border : 1px solid black;
  background-color:white;
  color:black;
  max-width: 100%;
  max-height: 95vh;
}

.MuiOutlinedInput-root legend {
  float: inherit;
}

.multiRow-cell-editor {
  /*position: relative; */
  left: 0px;
  width: 100%; 
  border-radius: 0px;
  margin: -4px 0 -3px 0;
}

.selectedDntInfo {
  background: rgb(248, 173, 173) !important;
  color: black;
}

.uneditableCell {
  box-shadow: 0px 8px 10px -20px rgba(72, 72, 77, 0.7), 0px 0px 25px -10px rgba(72, 72, 77, 0.7) inset;
  color: black;
}

.rightPos {
  text-align: right !important;
}

.leftPos {
  text-align: left !important;
}

.centerPos {
  text-align: center !important;
}

.MuiGrid-item p{
  white-space: pre-wrap;
}

.wj-multirow {
  margin: 6px 0;
}
.wj-flexgrid {
  margin-bottom: 12px;
}

.wj-flexgrid .wj-cell {
  padding: 8px;
}

.wj-flexgrid .wj-cell.alert {
  background-color: red;
}

.wj-flexgrid .wj-cell.warning {
  background-color: yellow;
}

.wj_HeaderCenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.wj_blockCenter div{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.wj-colheaders .wj-header {
  background: #001a37 !important;
  color: #fff !important;
  text-align: center !important;
  vertical-align: middle;
  border-left: solid 1px #fff; 
  border-bottom: solid 1px #fff; 
  border-collapse: collapse;
}

.wj-topleft .wj-header {
  background: #001a37 !important;
  color: #fff !important;
  text-align: center !important;
  vertical-align: middle;
  border: solid 1px #000000; 
  border-collapse: collapse;
}

.wj-cell.wj-has-notes:after {
  position: absolute;
  content: '';
  width: 0;
  right: 0;
  top: -10px;
  border: 10px solid transparent;
  border-right: 10px solid red;
  opacity: .5;
}
.wj-cell.wj-has-notes:hover:after {
  opacity: 1;
}

.wj-cells .wj-cell:hover {
  overflow: visible;
  z-index: 7 !important;
  color: black;
  text-shadow:
      /* many shadows blur out the area around the text */
      0 0 1px white,
      0 0 2px white,
      0 0 3px white,
      0 0 4px white,
      0 0 5px white,
      0 0 6px white,
      0 0 7px white,
      0 0 8px white,
      0 0 9px white,
      0 0 10px white
}

div[wj-part=dropdown] .wj-listbox-item {
  height: 24px;
}

/* prevent overflowing over the headers */
.wj-rowheaders,
.wj-colheaders,
.wj-topleft {
    z-index: 7;
}

.gray:not(.wj-state-selected) {
  background-color:gray !important;
  color:black !important;
} 

.MuiFormLabel-asterisk {
  color: red;
  font-weight: bold;
}

.wj-label-longText{
  padding-top: inherit;
  vertical-align: text-top !important;
}

/* wijmo popup style start */
.modal-content {
  width: auto;
  overflow: auto !important;
}

/* style our custom 'edit buttons' */
.wj-rowheaders .wj-cell .wj-glyph-pencil {
  opacity: .5;
  transform: scale(.75);
}
.wj-rowheaders .wj-cell:hover .wj-glyph-pencil {
  opacity: 1;
  color: #cc0000;
  transform: scale(1.5) rotate(-90deg);
  transition: all 400ms;
}

/* wj-labeled-input: adapted from MDL styles */

/* label input container */
.flexGrid-cell-editor {
  position: absolute; 
  left: 0px;
  width: 100%; 
  border-radius: 0px;
  margin: -4px 0 -3px 0;
}

.wj-labeled-input {
  position: relative;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0 20px;
  padding: 20px 0;
}

.wj-input-label-header {
  padding: 0px 0px 0px 0px;
  /* color: #0085c7; */
  color: rgb(63,81,181);
}

.wj-input-label-custom {
  font-size: 12px;
  position: relative;
  display:block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  padding: 0px 0px 0px 0px;
  /* color: #0085c7; */
  color: rgb(63,81,181);
}

/* Wijmo control in the container */
.wj-labeled-input .wj-control.wj-content {
  margin: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.wj-labeled-input .wj-control.wj-content button {
  opacity: 0.75;
  border-color: rgba(0,0,0,.1);
}

.wj-labeled-input .wj-input-group .wj-form-control {
  float: none;
}

.wj-cell.wj-header {
  justify-content:safe center !important;
}

/* multirow rowheader label style */
.wj-cell.wj-header.wj-align-center.wj-center-vert label {
  display:table-cell;
  vertical-align:middle;
  overflow:inherit;
  text-overflow:inherit;
  width:100%
}

/* multirow cell input form style */
.wj-cell .wj-dropdown .wj-input .wj-input-group {
  display: table;
}

.wj-cell .wj-dropdown .wj-input .wj-input-group button {
 border-left: 0;
 vertical-align: middle;
}

.wj-cell .wj-dropdown{
 display: table;
}

.wj-cell .wj-inputnumber .wj-input .wj-input-group {
 display: table;
}

/* label in the container (must come after the control) */
.wj-labeled-input label {
  font-size: 16px;
  top: 24px;
  bottom: 0;
  margin: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  color: rgba(0, 0, 0, 0.258824);
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

/* move label out of the way when control is focused or not empty */
.wj-static-labels .wj-labeled-input :not(.wj-state-focused) + label,
.wj-labeled-input .wj-state-focused + label,
.wj-labeled-input :not(.wj-state-empty) + label {
  font-size: 12px;
  top: 4px;
  color: rgb(63,81,181);
  visibility: visible;
}

.wj-labeled-input .wj-state-invalid + label {
  color: #d50000;
}

/* underline label */
.wj-labeled-input label:after {
  background-color: rgb(63,81,181);
  bottom: 20px;
  content: '';
  height: 2px;
  left: 45%;
  position: absolute;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  visibility: hidden;
  width: 10px;
}

/* show underline when focused */
.wj-labeled-input .wj-state-focused + label:after {
  left: 0;
  visibility: visible;
  width: 100%;
}

.wj-labeled-input .wj-state-invalid + label:after {
  background-color: #d50000;
}

/* validation message */
.wj-labeled-input .wj-error {
  color: #d50000;
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  visibility: hidden;
  display: block;
}

.wj-labeled-input .wj-control.wj-state-invalid ~ .wj-error {
  visibility: visible;
}

.wj-listbox-item {
  min-height: 20px;
}
/* wijmo popup style end */
